import React from 'react';
import { CardMedia, Box, Typography } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';

import SEO from '../components/seo';
import { RegistrationForm } from '../components/Auth';
import { Header, Content } from '../components/layouts';
import logoPath from '../images/logo.png';

const Register = () => {
  return (
    <>
      <SEO title="Register Nonprofit" />
      <Header noMenu backArrow />
      <Content md={4} alignMiddle>
        <Box pb={2} align="center">
          <Link to="/">
            <CardMedia
              src={logoPath}
              component="img"
              alt="Pintuna logo"
              style={{ width: '200px' }}
            />
          </Link>
        </Box>
        <Box py={2}>
          <Typography variant="body2" color="textSecondary">
            Register your organization with Pintuna and start fund raising! You
            must be an official representative of the organization to register
            as its administrator.
          </Typography>
        </Box>
        <RegistrationForm />
        <Box py={2}>
          <Typography variant="body2" color="textSecondary">
            By creating an account, you agree to Pintuna&apos;s{' '}
            <Link to="/conditions-of-use">Conditions of Use</Link> and{' '}
            <Link to="/privacy-notice">Privacy Notice</Link>.
          </Typography>
        </Box>
      </Content>
    </>
  );
};

export default Register;
